<template>
  <el-dialog :visible.sync="visible" width="90%" title="关联企业列表" @open="init">

    <div class="companyList">
      <el-table border v-loading="loading" :data="list">
        <el-table-column align="center" prop="organizationType" label="账户类型">
          <template slot-scope="{row}">
            <span v-if="row.organizationType===0">社区</span>
            <span v-if="row.organizationType===1">企业</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="companyName" label="公司名称" />
        <el-table-column align="center" prop="industrysId" label="所属行业" />
        <el-table-column align="center" prop="expiryDate" label="到期时间" />
        <el-table-column align="center" prop="status" label="状态">
          <template slot-scope="{row}">
            <el-tag v-if="row.status===0" type="info" size="small">已冻结</el-tag>
            <el-tag v-if="row.status===1" type="success" size="small">正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="purchasersNumber" label="人数包" />
        <el-table-column align="center" prop="activeStaff" label="在职人数" />
        <el-table-column align="center" prop="remainingDays" label="剩余天数">

          <template slot-scope="{row}">
            <el-tag v-if="row.remainingDays<=0" type="danger" size="small">已过期</el-tag>
            <span v-else>{{row.remainingDays}}</span>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" width="180px">
          <template slot-scope="{row}">
            <el-button size="mini" @click="edit(row)" v-if="isAuth('platform-admin:tenant:update')">编辑
            </el-button>
            <el-button size="mini" v-if=" row.status===1&&isAuth('platform-admin:tenant:status')" @click="operation(row,'冻结')" type="warning">冻结</el-button>
            <el-button size="mini" v-if=" row.status===0&&isAuth('platform-admin:tenant:status')" @click="operation(row,'解冻')" type="success">解冻</el-button>
            <el-button size="mini" v-if="isAuth('platform_config:thirdPartyConfig:delete') && row.status===0" type="danger" @click="remove(row)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <jf-page slot="foot" @change="getList" :page.sync="params.page" :limit.sync="params.limit" :total="total" />
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String | Number,
      default: ''
    }
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10
      },
      loading: false,
      total: 0,
      list: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    init() {
      this.list = [];

      this.params.id = this.id;
      this.search()
    },
    search() {
      this.params.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$get('/platform-admin/thirdPartyConfig/affiliatePage', this.params).then(res => {
        if (res && res.code === 0) {
          this.list = res.data.list;
          this.total = res.data.total;
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    edit(row) {
      this.$router.push({
        path: "/user-add",
        query: {
          id: row.accountId,
        },
      });
    },
    //操作
    operation(row, type) {
      var url;
      var msg;
      if (type === "冻结") {
        url = "/platform-admin/tenant/disable";
        msg = "此操作将冻结该用户, 是否继续?";
      }
      if (type === "解冻") {
        url = "/platform-admin/tenant/enable";
        msg = "此操作将解冻该用户, 是否继续?";
      }



      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$put(url, [row.tenantCode]);
          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: type + "成功!",
            });
            this.getList();
          }
        })
        .catch(() => { });
    },
    remove(row) {
      this.$confirm('确认执行移除操作?', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          var res = await this.$del('/platform-admin/thirdPartyConfigTenant', [row.id]);
          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "移除成功!",
            });
            this.search();
            this.$emit('removeCompany')
          }
        })
        .catch(() => { });
    }
  }
}
</script>

<style lang='scss' scoped >
.companyList {
  padding-bottom: 20px;
}
</style>
