var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.visible,"width":"90%","title":"关联企业列表"},on:{"update:visible":function($event){_vm.visible=$event},"open":_vm.init}},[_c('div',{staticClass:"companyList"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"border":"","data":_vm.list}},[_c('el-table-column',{attrs:{"align":"center","prop":"organizationType","label":"账户类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.organizationType===0)?_c('span',[_vm._v("社区")]):_vm._e(),(row.organizationType===1)?_c('span',[_vm._v("企业")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"companyName","label":"公司名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"industrysId","label":"所属行业"}}),_c('el-table-column',{attrs:{"align":"center","prop":"expiryDate","label":"到期时间"}}),_c('el-table-column',{attrs:{"align":"center","prop":"status","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status===0)?_c('el-tag',{attrs:{"type":"info","size":"small"}},[_vm._v("已冻结")]):_vm._e(),(row.status===1)?_c('el-tag',{attrs:{"type":"success","size":"small"}},[_vm._v("正常")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"purchasersNumber","label":"人数包"}}),_c('el-table-column',{attrs:{"align":"center","prop":"activeStaff","label":"在职人数"}}),_c('el-table-column',{attrs:{"align":"center","prop":"remainingDays","label":"剩余天数"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.remainingDays<=0)?_c('el-tag',{attrs:{"type":"danger","size":"small"}},[_vm._v("已过期")]):_c('span',[_vm._v(_vm._s(row.remainingDays))])]}}])}),_c('el-table-column',{attrs:{"align":"left","label":"操作","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.isAuth('platform-admin:tenant:update'))?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.edit(row)}}},[_vm._v("编辑 ")]):_vm._e(),( row.status===1&&_vm.isAuth('platform-admin:tenant:status'))?_c('el-button',{attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.operation(row,'冻结')}}},[_vm._v("冻结")]):_vm._e(),( row.status===0&&_vm.isAuth('platform-admin:tenant:status'))?_c('el-button',{attrs:{"size":"mini","type":"success"},on:{"click":function($event){return _vm.operation(row,'解冻')}}},[_vm._v("解冻")]):_vm._e(),(_vm.isAuth('platform_config:thirdPartyConfig:delete') && row.status===0)?_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.remove(row)}}},[_vm._v("移除")]):_vm._e()]}}])})],1),_c('jf-page',{attrs:{"slot":"foot","page":_vm.params.page,"limit":_vm.params.limit,"total":_vm.total},on:{"change":_vm.getList,"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.params, "limit", $event)}},slot:"foot"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }